import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Hero = styled.section`
  position: relative;
`;

const HeroContent = styled(Container)`
  z-index: 1;
  color: white;
  padding-top: 180px;
  @media (min-width: 1024px) {
    padding-top: 320px;
  }
  h1 {
    font-family: "Avenir";
    font-size: 4rem;
    padding-bottom: 75px;
  }
`;
const ArrowOverlay = styled.div`
  background-image: url("/images/arrow_overlay.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 300px;
  height: 220px;
  left: calc(100% - 300px);
  bottom: 0px;
  @media (min-width: 1024px) {
    width: 500px;
    height: 350px;
    left: calc(100% - 500px);
  }
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
`;

const OverviewHero = ({ title, heroImage }) => {
  const image = getImage(heroImage);

  return (
    <Hero>
      <BgImage
        style={{
          height: "100%",
          width: "100%",
        }}
        image={image}
      >
        <Overlay>
          <HeroContent>
            <Row>
              <Col>
                <h1>{title}</h1>
                {/* <ArrowOverlay /> */}
              </Col>
            </Row>
          </HeroContent>
        </Overlay>
      </BgImage>
    </Hero>
  );
};

export default OverviewHero;
